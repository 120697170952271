import { Auth } from "aws-amplify";
import axios from "axios";

export const backendApiClient = async () => {
  const token = await Auth.currentSession()
    .then((session) => session.getAccessToken().getJwtToken())
    .catch(() =>
      Auth.federatedSignIn({
        customProvider: "Zoho",
      })
    );
  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    timeout: 29000,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
