import { BrowserRouter, Routes, Route } from "react-router-dom";

import Lodgix from "./Lodgix";
import Zoho from "./Zoho";
import Home from "./Home";
import NavBar from "./NavBar";
import PrivateRoute from "./PrivateRoute";

const Router = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route index element={<Home />} />
        <Route element={<PrivateRoute />}>
          <Route path="lodgix" element={<Lodgix />} />
          <Route path="zoho" element={<Zoho />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
