import { useState } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Typography,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import moment from "moment-timezone";

import { backendApiClient } from "../../../helpers/backendApiClient";

const getStatusChip = (status: string) => {
  switch (status) {
    case "ADDED":
      return <Chip component="span" size="small" color="info" label="Added" />;
    case "IN PROGRESS":
      return (
        <Chip component="span" size="small" color="info" label="In Progress" />
      );
    case "COMPLETED":
      return (
        <Chip component="span" size="small" color="success" label="Completed" />
      );
    case "SKIPPED":
      return (
        <Chip component="span" size="small" color="warning" label="Skipped" />
      );
    case "FAILED":
      return (
        <Chip component="span" size="small" color="error" label="Failed" />
      );
    default:
      return null;
  }
};

enum ResourceTypes {
  all = "ALL",
  properties = "PROPERTIES",
  property_data = "PROPERTY_DATA",
  guests = "GUESTS",
  owners = "OWNERS",
  reservations = "RESERVATIONS",
}

const Activities = () => {
  const [resourceType, setResourceType] = useState<string>(ResourceTypes.all);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const [showApiError, setShowApiError] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["activities_zoho", resourceType],
    async ({ pageParam = "" }) => {
      let requestBody: {
        lastEvaluatedKey: any;
        filterExpressions?: any;
      } = {
        lastEvaluatedKey: pageParam,
      };
      if (resourceType !== ResourceTypes.all) {
        requestBody.filterExpressions = {
          resourceType,
        };
      }
      return await (
        await backendApiClient()
      ).post("/zoho/activities", requestBody);
    },
    {
      getNextPageParam: (lastPage) => lastPage.data.lastEvaluatedKey,
      onError: () => {
        setApiErrorMessage("Unable to fetch activities from database");
        setShowApiError(true);
      },
    }
  );

  return (
    <>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
          },
        }}
      >
        <TimelineItem>
          <TimelineOppositeContent />
          <TimelineContent>
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel id="type-label">Resource type</InputLabel>
              <Select
                labelId="type-label"
                value={resourceType}
                label="Resource type"
                onChange={(event: SelectChangeEvent) => {
                  setResourceType(event.target.value);
                }}
              >
                {Object.values(ResourceTypes).map((resourceType) => (
                  <MenuItem key={resourceType} value={resourceType}>
                    {resourceType.replace("_", " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent />
          <TimelineContent>
            <Button
              color="primary"
              onClick={() => {
                queryClient.resetQueries({ queryKey: "activities_zoho" });
              }}
              startIcon={<Refresh />}
            >
              <Typography>Reload Data</Typography>
            </Button>
          </TimelineContent>
        </TimelineItem>
        {data?.pages
          ?.map((page) => page.data.results)
          .flat()
          ?.map((activity: any, index: number) => (
            <TimelineItem key={activity.sk}>
              <TimelineOppositeContent color="textSecondary">
                {moment(activity.startTime).fromNow()}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                {index <
                  data?.pages?.map((page) => page.data.results).flat().length -
                    1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <List>
                  {activity.resourceType ? (
                    <ListItemText
                      primary="Resource Type"
                      secondary={
                        activity.resourceType.replace("_", " ")[0] +
                        activity.resourceType
                          .replace("_", " ")
                          .slice(1)
                          .toLocaleLowerCase()
                      }
                    />
                  ) : null}
                  {activity.status ? (
                    <ListItemText
                      primary="Status"
                      secondary={getStatusChip(activity.status)}
                    />
                  ) : null}
                  {activity.startTime ? (
                    <ListItemText
                      primary="Start Time"
                      secondary={moment(activity.startTime)
                        .tz(moment.tz.guess())
                        .format("LLLL")}
                    />
                  ) : null}
                  {activity.endTime ? (
                    <ListItemText
                      primary="End Time"
                      secondary={moment(activity.endTime)
                        .tz(moment.tz.guess())
                        .format("LLLL")}
                    />
                  ) : null}
                  {activity.startTime && activity.endTime ? (
                    <ListItemText
                      primary="Total Time"
                      secondary={moment
                        .duration(
                          moment(activity.endTime).diff(
                            moment(activity.startTime)
                          )
                        )
                        .humanize()}
                    />
                  ) : null}
                  {activity.processed && activity.processed !== 0 ? (
                    <ListItemText
                      primary={`${
                        activity.resourceType.replace("_", " ")[0] +
                        activity.resourceType
                          .replace("_", " ")
                          .slice(1)
                          .toLocaleLowerCase()
                      } synced successfully to Zoho`}
                      secondary={activity.processed}
                    />
                  ) : null}
                  {activity.skipped && activity.skipped !== 0 ? (
                    <ListItemText
                      primary={`${
                        activity.resourceType.replace("_", " ")[0] +
                        activity.resourceType
                          .replace("_", " ")
                          .slice(1)
                          .toLocaleLowerCase()
                      } skipped successfully from syncing to Zoho`}
                      secondary={activity.skipped}
                    />
                  ) : null}
                  {activity.count && activity.count !== 0 ? (
                    <ListItemText
                      primary={`Total ${
                        activity.resourceType.replace("_", " ")[0] +
                        activity.resourceType
                          .replace("_", " ")
                          .slice(1)
                          .toLocaleLowerCase()
                      } to sync`}
                      secondary={activity.count}
                    />
                  ) : null}
                </List>
              </TimelineContent>
            </TimelineItem>
          ))}
        {hasNextPage && (
          <TimelineItem>
            <TimelineOppositeContent />
            <TimelineContent>
              <LoadingButton
                variant="contained"
                loading={isFetching}
                onClick={() => fetchNextPage()}
                sx={{ ml: 1 }}
              >
                Load More
              </LoadingButton>
            </TimelineContent>
          </TimelineItem>
        )}
        {isFetching && (
          <TimelineItem>
            <TimelineOppositeContent />
            <TimelineContent>
              <CircularProgress />
            </TimelineContent>
          </TimelineItem>
        )}
      </Timeline>
      <Snackbar
        open={showApiError}
        autoHideDuration={6000}
        onClose={() => setShowApiError(false)}
      >
        <Alert
          onClose={() => setShowApiError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {apiErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Activities;
