import { useEffect, useState } from "react";
import { useInfiniteQuery, useQueryClient, useMutation } from "react-query";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Link,
  Snackbar,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Refresh, Sync } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import moment from "moment-timezone";

import FormattedAddress from "../../../shared/FormattedAddress";
import { backendApiClient } from "../../../helpers/backendApiClient";

const Guests = () => {
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const [showApiError, setShowApiError] = useState<boolean>(false);
  const [showSyncGuestsApiSuccess, setShowSyncGuestsApiSuccess] =
    useState<boolean>(false);
  const [
    syncGuestsConfirmationDialogOpen,
    setSyncGuestsConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [syncGuestsCount, setSyncGuestsCount] = useState<number>();
  const queryClient = useQueryClient();

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(
    "guests",
    async ({ pageParam = "" }) => {
      return await (
        await backendApiClient()
      ).post("/lodgix/guests", {
        lastEvaluatedKey: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => lastPage.data.lastEvaluatedKey,
      onError: () => {
        setApiErrorMessage("Unable to fetch guests from database");
        setShowApiError(true);
      },
    }
  );

  const { isLoading: syncGuestsApiLoading, mutate: startSyncGuests } =
    useMutation(
      "syncGuests",
      async () => {
        return await (await backendApiClient()).put("/lodgix/guests");
      },
      {
        onSuccess: (response) => {
          setSyncGuestsCount(response?.data?.count);
          setShowSyncGuestsApiSuccess(true);
          setSyncGuestsConfirmationDialogOpen(false);
        },
        onError: () => {
          setApiErrorMessage("Unable to start job to sync guests from lodgix");
          setShowApiError(true);
        },
      }
    );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, data]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      hideable: false,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "company",
      headerName: "Company",
      width: 120,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) =>
        value ? value[0].toLocaleUpperCase() + value.slice(1) : "-",
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => (
        <>
          {value ? (
            <Link href={`mailto:${value}`} target="_blank" rel="noreferrer">
              {value}
            </Link>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
      hideable: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => (
        <FormattedAddress
          address1={value.address1}
          address2={value.address2}
          city={value.city}
          state={value.state?.name}
          country={value.country?.code}
          zip={value.zip}
          phone={value.phone}
          work_phone={value.work_phone}
          work_phone_ext={value.work_phone_ext}
          fax={value.fax}
        />
      ),
    },
    {
      field: "mobile_phone",
      headerName: "Phone",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => (
        <>
          {value ? (
            <Link href={`tel:${value}`} target="_blank" rel="noreferrer">
              {value}
            </Link>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "last_stay_date",
      headerName: "Last Stay Date",
      width: 140,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) =>
        value ? moment(value).tz(moment.tz.guess()).format("LL") : "-",
    },
    {
      field: "num_stays",
      headerName: "Number of Stays",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "types",
      headerName: "Type",
      width: 100,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
      <DataGrid
        rows={data?.pages?.map((page) => page.data.results).flat() || []}
        columns={columns}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              owner_id: false,
              url: false,
              allow_pets: false,
              fees: false,
              rates: false,
              custom_variables: false,
            },
          },
        }}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        rowCount={data?.pages?.map((page) => page.data.results).flat().length}
        disableColumnFilter={isFetching}
        showCellVerticalBorder
        disableColumnMenu
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton disabled={isFetching} />
              <GridToolbarFilterButton />
              <Button
                size="small"
                color="primary"
                startIcon={<Sync />}
                onClick={() => setSyncGuestsConfirmationDialogOpen(true)}
              >
                Sync from Lodgix
              </Button>
              <Button
                size="small"
                color="primary"
                startIcon={<Refresh />}
                onClick={() =>
                  queryClient.resetQueries({ queryKey: "guests", exact: true })
                }
              >
                Reload Data
              </Button>
            </GridToolbarContainer>
          ),
          LoadingOverlay: LinearProgress,
        }}
        loading={isFetching}
      />
      <Dialog
        open={syncGuestsConfirmationDialogOpen}
        onClose={() => {
          !syncGuestsApiLoading && setSyncGuestsConfirmationDialogOpen(false);
        }}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to start job to sync guests from Lodgix?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!syncGuestsApiLoading && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setSyncGuestsConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            variant="contained"
            loading={syncGuestsApiLoading}
            onClick={() => {
              startSyncGuests();
            }}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSyncGuestsApiSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSyncGuestsApiSuccess(false)}
      >
        <Alert
          onClose={() => setShowSyncGuestsApiSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Sync successfully started for {syncGuestsCount} guests.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showApiError}
        autoHideDuration={6000}
        onClose={() => setShowApiError(false)}
      >
        <Alert
          onClose={() => setShowApiError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {apiErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Guests;
