import { useEffect, useState } from "react";
import { useInfiniteQuery, useQueryClient, useMutation } from "react-query";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  List,
  ListItemText,
  Snackbar,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Refresh, Sync } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import moment from "moment-timezone";

import { backendApiClient } from "../../../helpers/backendApiClient";

const Reservations = () => {
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const [selectedReservation, setSelectedReservation] = useState<any>();
  const [propertiesDialogOpen, setPropertiesDialogOpen] =
    useState<boolean>(false);
  const [showApiError, setShowApiError] = useState<boolean>(false);
  const [showSyncReservationsApiSuccess, setShowSyncReservationsApiSuccess] =
    useState<boolean>(false);
  const [
    syncReservationsConfirmationDialogOpen,
    setSyncReservationsConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [syncReservationsCount, setSyncReservationsCount] = useState<number>();
  const queryClient = useQueryClient();

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(
    "reservations",
    async ({ pageParam = "" }) => {
      return await (
        await backendApiClient()
      ).post("/lodgix/reservations", {
        lastEvaluatedKey: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => lastPage.data.lastEvaluatedKey,
      onError: () => {
        setApiErrorMessage("Unable to fetch reservations from database");
        setShowApiError(true);
      },
    }
  );

  const {
    isLoading: syncReservationsApiLoading,
    mutate: startSyncReservations,
  } = useMutation(
    "syncReservations",
    async () => {
      return await (
        await backendApiClient()
      ).put("/lodgix/reservations", {
        additionalParameters: {
          states: "CANCELLED,NOTPAID,PAID,PAIDDEPOSIT,PAIDOTHER",
        },
      });
    },
    {
      onSuccess: (response) => {
        setSyncReservationsCount(response?.data?.count);
        setShowSyncReservationsApiSuccess(true);
        setSyncReservationsConfirmationDialogOpen(false);
      },
      onError: () => {
        setApiErrorMessage(
          "Unable to start job to sync reservations from lodgix"
        );
        setShowApiError(true);
      },
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, data]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      hideable: false,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "guest_id",
      headerName: "Guest",
      width: 100,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "guests",
      headerName: "Number of Guests",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "children",
      headerName: "Number of Children",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "nights",
      headerName: "Number of Nights",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "notes",
      headerName: "Note",
      width: 250,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "substate",
      headerName: "Sub State",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "from_date",
      headerName: "From Date",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) =>
        value ? moment(value).tz(moment.tz.guess()).format("LL") : "-",
    },
    {
      field: "to_date",
      headerName: "To Date",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) =>
        value ? moment(value).tz(moment.tz.guess()).format("LL") : "-",
    },
    {
      field: "properties",
      headerName: "Properties",
      width: 180,
      hideable: true,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => (
        <>
          {row.properties?.length > 0 ? (
            <Button
              variant="text"
              onClick={() => {
                setSelectedReservation(row);
                setPropertiesDialogOpen(true);
              }}
            >
              View properties
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        rows={data?.pages?.map((page) => page.data.results).flat() || []}
        columns={columns}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              owner_id: false,
              url: false,
              allow_pets: false,
              fees: false,
              rates: false,
              custom_variables: false,
            },
          },
        }}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        rowCount={data?.pages?.map((page) => page.data.results).flat().length}
        disableColumnMenu
        showCellVerticalBorder
        disableColumnFilter={isFetching}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton disabled={isFetching} />
              <GridToolbarFilterButton />
              <Button
                size="small"
                color="primary"
                startIcon={<Sync />}
                onClick={() => setSyncReservationsConfirmationDialogOpen(true)}
              >
                Sync from Lodgix
              </Button>
              <Button
                size="small"
                color="primary"
                startIcon={<Refresh />}
                onClick={() =>
                  queryClient.resetQueries({
                    queryKey: "reservations",
                    exact: true,
                  })
                }
              >
                Reload Data
              </Button>
            </GridToolbarContainer>
          ),
          LoadingOverlay: LinearProgress,
        }}
        loading={isFetching}
      />
      <Dialog
        open={propertiesDialogOpen}
        onClose={() => setPropertiesDialogOpen(false)}
        scroll="paper"
      >
        <DialogTitle>Properties</DialogTitle>
        <DialogContent dividers={true}>
          {selectedReservation?.properties?.length > 0
            ? selectedReservation.properties.map(
                (property: {
                  id: number;
                  property_id: number;
                  room_id: number;
                  pets: number;
                  door_code: number;
                }) => (
                  <List key={property.id}>
                    <ListItemText
                      primary="Property Id"
                      secondary={property.property_id}
                    />
                    <ListItemText
                      primary="Room Id"
                      secondary={property.room_id}
                    />
                    <ListItemText
                      primary="Door Code"
                      secondary={property.door_code}
                    />
                    <ListItemText
                      primary="Number of pets"
                      secondary={property.pets}
                    />
                  </List>
                )
              )
            : "No properties data available"}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPropertiesDialogOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={syncReservationsConfirmationDialogOpen}
        onClose={() => {
          !syncReservationsApiLoading &&
            setSyncReservationsConfirmationDialogOpen(false);
        }}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to start job to sync reservations from Lodgix?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!syncReservationsApiLoading && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setSyncReservationsConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            variant="contained"
            loading={syncReservationsApiLoading}
            onClick={() => {
              startSyncReservations();
            }}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSyncReservationsApiSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSyncReservationsApiSuccess(false)}
      >
        <Alert
          onClose={() => setShowSyncReservationsApiSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Sync successfully started for {syncReservationsCount} reservations.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showApiError}
        autoHideDuration={6000}
        onClose={() => setShowApiError(false)}
      >
        <Alert
          onClose={() => setShowApiError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {apiErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Reservations;
