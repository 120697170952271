import { Stack, Typography } from "@mui/material";

interface FormattedAddressProps {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  phone?: string;
  work_phone?: string;
  work_phone_ext?: string;
  fax?: string;
}

const FormattedAddress = (props: FormattedAddressProps) => {
  return (
    <Stack direction="column" spacing={0.1} sx={{ pl: 0.5, pr: 0.5 }}>
      <Typography>
        {(props.address1 || props.address2) &&
          props.address1 +
            (props.address2 ? " " + props.address2 + ", " : ", ")}
        {props.city && props.city + ", "}
        {props.state && props.state + ", "}
        {props.zip && props.zip + ", "}
        {props.country && props.country}
      </Typography>
      {props.phone && <Typography>{props.phone}</Typography>}
      {props.work_phone && (
        <Typography>
          {props.work_phone} {props.work_phone_ext}
        </Typography>
      )}
      {props.fax && <Typography>{props.fax}</Typography>}
    </Stack>
  );
};

export default FormattedAddress;
