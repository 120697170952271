import { useEffect, useState } from "react";
import { useInfiniteQuery, useQueryClient, useMutation } from "react-query";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Link,
  List,
  ListItemText,
  Snackbar,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Refresh, Sync } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import FormattedAddress from "../../../shared/FormattedAddress";
import { backendApiClient } from "../../../helpers/backendApiClient";

const Owners = () => {
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const [selectedOwner, setSelectedOwner] = useState<any>();
  const [propertiesDialogOpen, setPropertiesDialogOpen] =
    useState<boolean>(false);
  const [showApiError, setShowApiError] = useState<boolean>(false);
  const [showSyncOwnersApiSuccess, setShowSyncOwnersApiSuccess] =
    useState<boolean>(false);
  const [
    syncOwnersConfirmationDialogOpen,
    setSyncOwnersConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [syncOwnersCount, setSyncOwnersCount] = useState<number>();
  const queryClient = useQueryClient();

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(
    "owners",
    async ({ pageParam = "" }) => {
      return await (
        await backendApiClient()
      ).post("/lodgix/owners", {
        lastEvaluatedKey: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => lastPage.data.lastEvaluatedKey,
      onError: () => {
        setApiErrorMessage("Unable to fetch owners from database");
        setShowApiError(true);
      },
    }
  );

  const { isLoading: syncOwnersApiLoading, mutate: startSyncOwners } =
    useMutation(
      "syncOwners",
      async () => {
        return await (await backendApiClient()).put("/lodgix/owners");
      },
      {
        onSuccess: (response) => {
          setSyncOwnersCount(response?.data?.count);
          setShowSyncOwnersApiSuccess(true);
          setSyncOwnersConfirmationDialogOpen(false);
        },
        onError: () => {
          setApiErrorMessage("Unable to start job to sync owners from lodgix");
          setShowApiError(true);
        },
      }
    );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, data]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      hideable: false,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => (
        <>
          {value ? (
            <Link href={`mailto:${value}`} target="_blank" rel="noreferrer">
              {value}
            </Link>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
      hideable: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => (
        <FormattedAddress
          address1={value.address1}
          address2={value.address2}
          city={value.city}
          state={value.state?.name}
          country={value.country?.code}
          zip={value.zip}
          phone={value.phone}
          work_phone={value.work_phone}
          work_phone_ext={value.work_phone_ext}
          fax={value.fax}
        />
      ),
    },
    {
      field: "properties",
      headerName: "Properties",
      width: 180,
      hideable: true,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => (
        <>
          {row.properties?.length > 0 ? (
            <Button
              variant="text"
              onClick={() => {
                setSelectedOwner(row);
                setPropertiesDialogOpen(true);
              }}
            >
              View properties
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        rows={data?.pages?.map((page) => page.data.results).flat() || []}
        columns={columns}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              owner_id: false,
              url: false,
              allow_pets: false,
              fees: false,
              rates: false,
              custom_variables: false,
            },
          },
        }}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        rowCount={data?.pages?.map((page) => page.data.results).flat().length}
        disableColumnMenu
        showCellVerticalBorder
        disableColumnFilter={isFetching}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton disabled={isFetching} />
              <GridToolbarFilterButton />
              <Button
                size="small"
                color="primary"
                startIcon={<Sync />}
                onClick={() => setSyncOwnersConfirmationDialogOpen(true)}
              >
                Sync from Lodgix
              </Button>
              <Button
                size="small"
                color="primary"
                startIcon={<Refresh />}
                onClick={() =>
                  queryClient.resetQueries({ queryKey: "owners", exact: true })
                }
              >
                Reload Data
              </Button>
            </GridToolbarContainer>
          ),
          LoadingOverlay: LinearProgress,
        }}
        loading={isFetching}
      />
      <Dialog
        open={propertiesDialogOpen}
        onClose={() => setPropertiesDialogOpen(false)}
        scroll="paper"
      >
        <DialogTitle>Properties</DialogTitle>
        <DialogContent dividers={true}>
          {selectedOwner?.properties?.length > 0
            ? selectedOwner.properties.map(
                (property: {
                  name: string;
                  id: number;
                  rental_property_id: number;
                }) => (
                  <List key={property.id}>
                    <ListItemText
                      primary="Property Id"
                      secondary={property.rental_property_id}
                    />
                    <ListItemText
                      primary="Property Name"
                      secondary={property.name}
                    />
                  </List>
                )
              )
            : "No properties data available"}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPropertiesDialogOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={syncOwnersConfirmationDialogOpen}
        onClose={() => {
          !syncOwnersApiLoading && setSyncOwnersConfirmationDialogOpen(false);
        }}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to start job to sync owners from Lodgix?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!syncOwnersApiLoading && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setSyncOwnersConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            variant="contained"
            loading={syncOwnersApiLoading}
            onClick={() => {
              startSyncOwners();
            }}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSyncOwnersApiSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSyncOwnersApiSuccess(false)}
      >
        <Alert
          onClose={() => setShowSyncOwnersApiSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Sync successfully started for {syncOwnersCount} owners.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showApiError}
        autoHideDuration={6000}
        onClose={() => setShowApiError(false)}
      >
        <Alert
          onClose={() => setShowApiError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {apiErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Owners;
