import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import Activities from "./Activities";
import Sync from "./Sync";
import { useSearchParams } from "react-router-dom";

const Zoho = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <TabContext
        value={searchParams.get("tab")?.toLocaleLowerCase() || "sync"}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(event, value) => {
              setSearchParams(`tab=${value}`, { replace: true });
            }}
            variant="scrollable"
            allowScrollButtonsMobile
          >
            <Tab label="Sync" value="sync" />
            <Tab label="Activities" value="activities" />
          </TabList>
        </Box>
        <TabPanel value="sync" sx={{ height: "85vh", padding: 1 }}>
          <Sync />
        </TabPanel>
        <TabPanel value="activities">
          <Activities />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Zoho;
