import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { Box, Button, Container, Typography, Stack } from "@mui/material";

const PrivateRoute = () => {
  const [user, setUser] = useState<CognitoUser | null>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch(() =>
        Auth.federatedSignIn({
          customProvider: "Zoho",
        })
      );
  }, []);

  return (
    <>
      {user ? (
        <Outlet />
      ) : (
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Redirecting to sign in automatically...
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button
                variant="contained"
                onClick={() =>
                  Auth.federatedSignIn({
                    customProvider: "Zoho",
                  })
                }
              >
                Sign in
              </Button>
            </Stack>
          </Container>
        </Box>
      )}
    </>
  );
};

export default PrivateRoute;
