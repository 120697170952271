import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { Box, Button, Container, Typography, Stack } from "@mui/material";

const Home = () => {
  const [user, setUser] = useState<null | CognitoUser>(null);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch(() => {});
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        pt: 8,
        pb: 6,
      }}
    >
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {process.env.REACT_APP_NAME}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          paragraph
        >
          This project is for integrating different platforms used by Jayne's
          Luxury Rental team, such as Lodgix, Zoho CRM.
        </Typography>
        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          {user ? (
            <>
              <Button variant="contained" onClick={() => navigate("/lodgix")}>
                Lodgix Integration
              </Button>
              <Button variant="contained" onClick={() => navigate("/zoho")}>
                Zoho Integration
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={() =>
                Auth.federatedSignIn({
                  customProvider: "Zoho",
                })
              }
            >
              Sign in to Access
            </Button>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default Home;
