import { useState, MouseEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  Tooltip,
  MenuItem,
  SvgIcon,
  Avatar,
} from "@mui/material";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";

import { ReactComponent as Logo } from "../logo.svg";

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [user, setUser] = useState<null | CognitoUser>(null);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch(() => {});
  }, []);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <SvgIcon
            component={Logo}
            href="/"
            inheritViewBox
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "none", sm: "flex" },
              fontSize: "4em",
              width: "2em",
              height: "2em",
            }}
          />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", sm: "none" },
              }}
            >
              <MenuItem onClick={() => navigate("/lodgix")}>
                <Typography textAlign="center">Lodgix</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate("/zoho")}>
                <Typography textAlign="center">Zoho</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <SvgIcon
            component={Logo}
            href="/"
            inheritViewBox
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "flex", sm: "none" },
              flexGrow: 1,
              fontSize: "3em",
              width: "1.5em",
              height: "1.5em",
            }}
          />
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
            <Button
              onClick={() => navigate("/lodgix")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Lodgix
            </Button>
            <Button
              onClick={() => navigate("/zoho")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Zoho
            </Button>
          </Box>
          {user ? (
            <Box sx={{ flexGrow: 1, textAlign: "right" }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    src={
                      user.getSignInUserSession()?.getIdToken().payload?.picture
                    }
                    alt={`${
                      user.getSignInUserSession()?.getIdToken().payload
                        .given_name
                    } ${
                      user.getSignInUserSession()?.getIdToken().payload
                        .family_name
                    }`}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={() => {}} color="primary">
                  <Typography textAlign="center">
                    {
                      user.getSignInUserSession()?.getIdToken().payload
                        .given_name
                    }{" "}
                    {
                      user.getSignInUserSession()?.getIdToken().payload
                        .family_name
                    }
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    Auth.signOut();
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Sign Out</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box sx={{ flexGrow: 1, textAlign: "right" }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircle fontSize="large" sx={{ color: "#ffffff" }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    Auth.federatedSignIn({
                      customProvider: "Zoho",
                    });
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Sign In</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
