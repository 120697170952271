import { useState } from "react";
import { useMutation } from "react-query";
import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { backendApiClient } from "../../../helpers/backendApiClient";

const Sync = () => {
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const [contextResource, setContextResource] = useState<string>("");
  const [
    syncResourceConfirmationDialogOpen,
    setSyncResourceConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [showSyncResourceApiSuccess, setShowSyncResourceApiSuccess] =
    useState<boolean>(false);
  const [showApiError, setShowApiError] = useState<boolean>(false);

  const { isLoading: resourceSyncApiLoading, mutate: startResourceSync } =
    useMutation(
      "syncResource",
      async (resource: string) => {
        return await (await backendApiClient()).put(`/zoho/${resource}`);
      },
      {
        onSuccess: () => {
          setShowSyncResourceApiSuccess(true);
          setSyncResourceConfirmationDialogOpen(false);
        },
        onError: () => {
          setApiErrorMessage("Unable to start job to sync  to Zoho");
          setShowApiError(true);
        },
      }
    );

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        pt: 8,
        pb: 6,
      }}
    >
      <Container maxWidth="xs">
        <Stack
          sx={{ pt: 4 }}
          direction="column"
          spacing={2}
          justifyContent="center"
        >
          <Button
            variant="contained"
            onClick={() => {
              setContextResource("owners");
              setSyncResourceConfirmationDialogOpen(true);
            }}
          >
            Start sync for Owners
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setContextResource("properties");
              setSyncResourceConfirmationDialogOpen(true);
            }}
          >
            Start sync for Properties
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setContextResource("guests");
              setSyncResourceConfirmationDialogOpen(true);
            }}
          >
            Start sync for Guests
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setContextResource("reservations");
              setSyncResourceConfirmationDialogOpen(true);
            }}
          >
            Start sync for Reservations
          </Button>
        </Stack>
        <Dialog
          open={syncResourceConfirmationDialogOpen}
          onClose={() => {
            !resourceSyncApiLoading &&
              setSyncResourceConfirmationDialogOpen(false);
          }}
        >
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to start job to sync {contextResource} to
              Zoho?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!resourceSyncApiLoading && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setSyncResourceConfirmationDialogOpen(false)}
              >
                Cancel
              </Button>
            )}
            <LoadingButton
              variant="contained"
              loading={resourceSyncApiLoading}
              onClick={() => {
                startResourceSync(contextResource);
              }}
            >
              Yes
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Container>
      <Snackbar
        open={showSyncResourceApiSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSyncResourceApiSuccess(false)}
      >
        <Alert
          onClose={() => setShowSyncResourceApiSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Sync successfully started to zoho from {contextResource}.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showApiError}
        autoHideDuration={6000}
        onClose={() => setShowApiError(false)}
      >
        <Alert
          onClose={() => setShowApiError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {apiErrorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Sync;
