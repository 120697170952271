import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import Activities from "./Activities";
import Guests from "./Guests";
import Owners from "./Owners";
import Properties from "./Properties";
import Reservations from "./Reservations";
import { useSearchParams } from "react-router-dom";

const Lodgix = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Box sx={{ border: 1, borderColor: "divider" }}>
      <TabContext
        value={searchParams.get("tab")?.toLocaleLowerCase() || "properties"}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(event, value) => {
              setSearchParams(`tab=${value}`, { replace: true });
            }}
            variant="scrollable"
            allowScrollButtonsMobile
          >
            <Tab label="Properties" value="properties" />
            <Tab label="Guests" value="guests" />
            <Tab label="Owners" value="owners" />
            <Tab label="Reservations" value="reservations" />
            <Tab label="Activities" value="activities" />
          </TabList>
        </Box>
        <TabPanel value="properties" sx={{ height: "85vh", padding: 1 }}>
          <Properties />
        </TabPanel>
        <TabPanel value="guests" sx={{ height: "85vh", padding: 1 }}>
          <Guests />
        </TabPanel>
        <TabPanel value="owners" sx={{ height: "85vh", padding: 1 }}>
          <Owners />
        </TabPanel>
        <TabPanel value="reservations" sx={{ height: "85vh", padding: 1 }}>
          <Reservations />
        </TabPanel>
        <TabPanel value="activities">
          <Activities />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Lodgix;
