import { useEffect, useState } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Link,
  List,
  ListItemText,
  Snackbar,
  Stack,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Refresh, Sync } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import FormattedAddress from "../../../shared/FormattedAddress";
import { backendApiClient } from "../../../helpers/backendApiClient";

const Properties = () => {
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const [customVariablesDialogOpen, setCustomVariablesDialogOpen] =
    useState<boolean>(false);
  const [feesDialogOpen, setFeesDialogOpen] = useState<boolean>(false);
  const [ratesDialogOpen, setRatesDialogOpen] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] = useState<any>();
  const [showApiError, setShowApiError] = useState<boolean>(false);
  const [showSyncPropertiesApiSuccess, setShowSyncPropertiesApiSuccess] =
    useState<boolean>(false);
  const [
    syncPropertiesConfirmationDialogOpen,
    setSyncPropertiesConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [syncPropertiesCount, setSyncPropertiesCount] = useState<number>();
  const queryClient = useQueryClient();

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(
    "properties",
    async ({ pageParam = "" }) => {
      return await (
        await backendApiClient()
      ).post("/lodgix/properties", {
        lastEvaluatedKey: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => lastPage.data.lastEvaluatedKey,
      onError: () => {
        setApiErrorMessage("Unable to fetch properties from database");
        setShowApiError(true);
      },
    }
  );

  const { isLoading: syncPropertiesApiLoading, mutate: startSyncProperties } =
    useMutation(
      "syncProperties",
      async () => {
        return await (
          await backendApiClient()
        ).put("/lodgix/properties", {
          additionalParameters: {
            with_disabled: "true",
          },
        });
      },
      {
        onSuccess: (response) => {
          setSyncPropertiesCount(response?.data?.count);
          setShowSyncPropertiesApiSuccess(true);
          setSyncPropertiesConfirmationDialogOpen(false);
        },
        onError: () => {
          setApiErrorMessage(
            "Unable to start job to sync properties from lodgix"
          );
          setShowApiError(true);
        },
      }
    );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, data]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      hideable: false,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "owner_id",
      headerName: "Owner",
      width: 100,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "category",
      headerName: "Category",
      width: 120,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) =>
        value ? value[0].toLocaleUpperCase() + value.slice(1) : "-",
    },
    {
      field: "url",
      headerName: "URL",
      width: 500,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => (
        <>
          {value ? (
            <Link href={value} target="_blank" rel="noreferrer">
              {value}
            </Link>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
      hideable: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => (
        <FormattedAddress
          address1={value.address1}
          address2={value.address2}
          city={value.city}
          state={value.state?.name}
          country={value.country?.code}
          zip={value.zip}
          phone={value.phone}
          work_phone={value.work_phone}
          work_phone_ext={value.work_phone_ext}
          fax={value.fax}
        />
      ),
    },
    {
      field: "allow_pets",
      headerName: "Pets allowed",
      width: 120,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => (value ? "Yes" : "No"),
    },
    {
      field: "max_guests",
      headerName: "Maximum guests",
      width: 140,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "bedrooms",
      headerName: "Bedrooms",
      width: 100,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "bathrooms",
      headerName: "Bathrooms",
      width: 100,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "disabled",
      headerName: "Disabled",
      width: 100,
      hideable: true,
      sortable: true,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => (value ? "Yes" : "No"),
    },
    {
      field: "fees",
      headerName: "Fees",
      width: 120,
      hideable: true,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => (
        <>
          {row.fees?.length > 0 ? (
            <Button
              variant="text"
              onClick={() => {
                setSelectedProperty(row);
                setFeesDialogOpen(true);
              }}
            >
              View fees
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "rates",
      headerName: "Rates",
      width: 130,
      hideable: true,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => (
        <>
          {row.rates?.length > 0 ? (
            <Button
              variant="text"
              onClick={() => {
                setSelectedProperty(row);
                setRatesDialogOpen(true);
              }}
            >
              View rates
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "custom_variables",
      headerName: "Custom variables",
      width: 180,
      hideable: true,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => (
        <>
          {row.custom_variables?.length > 0 ? (
            <Button
              variant="text"
              onClick={() => {
                setSelectedProperty(row);
                setCustomVariablesDialogOpen(true);
              }}
            >
              View custom variables
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 500,
      hideable: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => (
        <>
          {value ? (
            <Stack direction="row" flexWrap="wrap">
              {value.map((tag: string, index: number) => (
                <Chip
                  key={`${tag}_${index}`}
                  label={tag}
                  size="small"
                  sx={{ m: 0.4 }}
                />
              ))}
            </Stack>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        rows={data?.pages?.map((page) => page.data.results).flat() || []}
        columns={columns}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              owner_id: false,
              url: false,
              allow_pets: false,
              fees: false,
              rates: false,
              custom_variables: false,
            },
          },
        }}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        rowCount={data?.pages?.map((page) => page.data.results).flat().length}
        disableColumnMenu
        showCellVerticalBorder
        disableColumnFilter={isFetching}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton disabled={isFetching} />
              <GridToolbarFilterButton />
              <Button
                size="small"
                color="primary"
                startIcon={<Sync />}
                onClick={() => setSyncPropertiesConfirmationDialogOpen(true)}
              >
                Sync from Lodgix
              </Button>
              <Button
                size="small"
                color="primary"
                startIcon={<Refresh />}
                onClick={() =>
                  queryClient.resetQueries({
                    queryKey: "properties",
                    exact: true,
                  })
                }
              >
                Reload Data
              </Button>
            </GridToolbarContainer>
          ),
          LoadingOverlay: LinearProgress,
        }}
        loading={isFetching}
      />
      <Dialog
        open={feesDialogOpen}
        onClose={() => setFeesDialogOpen(false)}
        scroll="paper"
      >
        <DialogTitle>Fees</DialogTitle>
        <DialogContent dividers={true}>
          {selectedProperty?.fees?.length > 0
            ? selectedProperty.fees.map(
                (fee: { id: number; title: string; value: number }) => (
                  <List key={fee.id}>
                    <ListItemText primary={fee.title} secondary={fee.value} />
                  </List>
                )
              )
            : "No fees data available"}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeesDialogOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={ratesDialogOpen}
        onClose={() => setRatesDialogOpen(false)}
        scroll="paper"
      >
        <DialogTitle>Rates</DialogTitle>
        <DialogContent dividers={true}>
          {selectedProperty?.rates?.length > 0
            ? selectedProperty.rates.map(
                (rate: {
                  period: {
                    from_date: string;
                    to_date: string;
                  };
                  arrival_day: string;
                  departure_day: string;
                  daily_rate: string;
                  weekend_rate: string;
                  extra_person_base: number;
                  weekly_rate: string;
                  monthly_rate: string;
                  default: string;
                  name: string;
                  id: number;
                  min_nights: number;
                }) => (
                  <List key={rate.id}>
                    <ListItemText primary={rate.name} />
                    <ListItemText
                      secondary={`Default: ${rate.default ? "Yes" : "No"}`}
                    />
                    {rate.period && (
                      <ListItemText
                        secondary={`Period: ${
                          rate.period.from_date + " to " + rate.period.to_date
                        }`}
                      />
                    )}
                    {rate.daily_rate && (
                      <ListItemText
                        secondary={`Daily rate: ${rate.daily_rate}`}
                      />
                    )}
                    {rate.weekend_rate && (
                      <ListItemText
                        secondary={`Weekend rate: ${rate.weekend_rate}`}
                      />
                    )}
                    {rate.weekly_rate && (
                      <ListItemText
                        secondary={`Weekly rate: ${rate.weekly_rate}`}
                      />
                    )}
                    {rate.monthly_rate && (
                      <ListItemText
                        secondary={`Monthly rate: ${rate.monthly_rate}`}
                      />
                    )}
                    {rate.min_nights && (
                      <ListItemText
                        secondary={`Minimum nights: ${rate.min_nights}`}
                      />
                    )}
                    {rate.arrival_day && (
                      <ListItemText
                        secondary={`Arrival Day: ${rate.arrival_day}`}
                      />
                    )}
                    {rate.departure_day && (
                      <ListItemText
                        secondary={`Departure Day: ${rate.departure_day}`}
                      />
                    )}
                  </List>
                )
              )
            : "No rates data available"}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRatesDialogOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={customVariablesDialogOpen}
        onClose={() => setCustomVariablesDialogOpen(false)}
        scroll="paper"
      >
        <DialogTitle>Fees</DialogTitle>
        <DialogContent dividers={true}>
          {selectedProperty?.custom_variables?.length > 0
            ? selectedProperty.custom_variables.map(
                (custom_variable: {
                  name: string;
                  description: string;
                  value: string;
                }) => (
                  <List key={custom_variable.name}>
                    <ListItemText
                      primary={custom_variable.description}
                      secondary={custom_variable.value}
                    />
                  </List>
                )
              )
            : "No custom variables data available"}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomVariablesDialogOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={syncPropertiesConfirmationDialogOpen}
        onClose={() => {
          !syncPropertiesApiLoading &&
            setSyncPropertiesConfirmationDialogOpen(false);
        }}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to start job to sync properties from Lodgix?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!syncPropertiesApiLoading && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setSyncPropertiesConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            variant="contained"
            loading={syncPropertiesApiLoading}
            onClick={() => {
              startSyncProperties();
            }}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSyncPropertiesApiSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSyncPropertiesApiSuccess(false)}
      >
        <Alert
          onClose={() => setShowSyncPropertiesApiSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Sync successfully started for {syncPropertiesCount} properties.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showApiError}
        autoHideDuration={6000}
        onClose={() => setShowApiError(false)}
      >
        <Alert
          onClose={() => setShowApiError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {apiErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Properties;
